var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"DUfRkZ-X07GZzqn9K5ejW"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs';
import { format } from 'date-fns';
import { sentryIgnoreConfig } from './src/utils/configs/sentryIgnore.config';

const SENTRY_DSN = process.env.FRONT_SENTRY_DSN;
const isSandbox = process.env.IS_SANDBOX === 'TRUE';
const environment = process.env.APP_ENV + (isSandbox ? '-sandbox' : '') + '-client';

Sentry.init({
    dsn: SENTRY_DSN,
    tracesSampleRate: 0.1,
    autoSessionTracking: false,
    environment,
    attachStacktrace: true,
    ignoreErrors: sentryIgnoreConfig,
    ignoreUrls: [
        /https:\/\/my.pochtabank.ru\/.+/gm,
        /https:\/\/analytics.tiktok.com\/.+/gm,
        /https:\/\/px.adhigh.net.+/gm,
        /https:\/\/api.flocktory.com.+/gm,
        /https:\/\/www.googletagmanager.com.+/gm,
        /https:\/\/dm.hybrid.ai.+/gm,
    ],
    denyUrls: [
        /https:\/\/my.pochtabank.ru\/.+/gm,
        /https:\/\/analytics.tiktok.com\/.+/gm,
        /https:\/\/px.adhigh.net.+/gm,
        /https:\/\/api.flocktory.com.+/gm,
        /https:\/\/www.googletagmanager.com.+/gm,
        /https:\/\/dm.hybrid.ai.+/gm,
    ],
    release: format(new Date(), 'dd-MM-yy__hh-mm'),
    beforeSend(event, hint) {
        if (event.exception?.values?.[0].value.includes('Invariant: attempted to hard navigate to the same URL')) {
            console.log('sentry', event);
        }

        if (sentryIgnoreConfig.find(item => hint?.originalException?.stack?.includes(item))) {
            return null;
        }

        return {
            ...event,
            environment,
        };
    },
});
