export const sentryIgnoreConfig = [
    'animatedPromo/AnimatedPromo/index.tsx',
    // Старые браузеры
    "Unexpected token '<'",
    'Unexpected token <',
    // Ошибки px.adhigh.net
    "Failed to load 'https://px.adhigh.net",
    'https://px.adhigh.net',
    // Ошибки тикток
    '(error: https://analytics.tiktok.com/i18n/pixel/identify.js)',
    'https://analytics.tiktok.com',
    // Ошибки скрипта карты на /map
    'sdk/v1/map',
    'Target container is not a DOM element.',
    'modern/mapSdk',
    // Ошибки трекеров и сторонних скриптов с my.pochtabank
    'trackSdk',
    'sdk/v1/product-lead',
    'window.cb',
    'JSBRIDGE TIMEOUT',
    'VK is not defined',
    'my.pochtabank.ru',
    'api.flocktory.com',
    'dm.hybrid.ai',
    'productLead',
    'sp.otm-r.com',
    'modern/creditIssueSdk',
    'paymentDefer',
    'DecimalError',
    'modern/creditPayment',
    // Ошибки gtag
    'www.googletagmanager.com',
    'ym is not defined',
    // в safari какая-то ошибка при парсинге schema.org
    'r["@context"].toLowerCase',
    'U+009E',
    'ResizeObserver loop limit exceeded',
];
